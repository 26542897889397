import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  DarkMode,
  Heading,
  Flex,
  Button,
  Center,
  Icon,
} from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { AiOutlineArrowRight } from 'react-icons/ai'
import FadeIn from 'components/Shared/FadeIn'
import { useApp } from 'context/App'
import MotionBoxInView from 'components/Shared/MotionBoxInView'
import ReactPlayer from 'react-player'

const Blurb = ({
  heading,
  body,
  delay,
  // numberBorderColor,
  // numberBgColor,
  // numberColor,
  // numberText,
}) => {
  const { isMobile } = useApp()

  return (
    <FadeIn
      delay={delay}
      maxW={isMobile ? undefined : '360px'}
      mb={isMobile ? '8px' : 0}
      flexShrink={0}
    >
      <Flex
        flexDir="column"
        alignItems="center"
        h="400px"
        p="32px"
        bg="#222"
        position="relative"
      >
        {/* <FadeIn delay={delay} duration={1.5} position="absolute" top="-40px">
          <Box
            boxSize="96px"
            bg={numberBgColor}
            color={numberColor}
            border="1px solid"
            borderColor={numberBorderColor}
            borderRadius="50%"
          >
            <Center font="arial" fontWeight="bold" h="100%" fontSize="40px">
              {numberText}
            </Center>
          </Box>
        </FadeIn> */}
        <Flex
          h={isMobile ? 'auto' : '120px'}
          pt={isMobile ? '40px' : undefined}
        >
          <Heading
            fontWeight="black"
            textAlign="left"
            fontSize="28px"
            justifySelf="flex-end"
            mb="32px"
          >
            {heading}
          </Heading>
        </Flex>
        <Box textAlign="left" color="#bababa" flex={1} fontSize="18px">
          {body}
        </Box>
      </Flex>
    </FadeIn>
  )
}

Blurb.propTypes = {
  delay: PropTypes.number,
  heading: PropTypes.any,
  body: PropTypes.any,
  numberBgColor: PropTypes.string,
  numberBorderColor: PropTypes.string,
  numberColor: PropTypes.string,
  numberText: PropTypes.string,
}

const WhatWeDo = () => {
  const [isVideoInView, setIsVideoInView] = useState(false)
  const { isMobile } = useApp()

  return (
    <DarkMode>
      <ContentMaxWidth
        display="block"
        color="white"
        bg="black"
        pt={isMobile ? '88px' : '140px'}
        pb={isMobile ? '88px' : '140px'}
      >
        <Box maxW="540px" mb="32px" px="16px">
          <Heading
            fontWeight="black"
            letterSpacing={-2}
            lineHeight={1.2}
            textAlign="left"
            size="2xl"
            mb="24px"
          >
            Stop attacks with the tools you already have.
          </Heading>
          <Box fontSize="24px" letterSpacing={-0.75} textAlign="left" mb="32px">
            The average security team is missing out on over 80% of the value
            their tools can provide. Reach custom-fits the tools you already
            have to cover your people, your business, and your unique threat
            landscape.
          </Box>
        </Box>

        {!isMobile && (
          <Box>
            <MotionBoxInView
              py={isMobile ? '32px' : undefined}
              mb="64px"
              onInView={() => setIsVideoInView(true)}
            >
              <ReactPlayer
                url="videos/reach_schematic.mp4"
                width="100%"
                height="100%"
                controls={false}
                playing={isVideoInView}
                playsinline
                loop
                muted
              />
            </MotionBoxInView>
          </Box>
        )}

        <Flex
          flexDir={isMobile ? 'column' : 'row'}
          mb="80px"
          w="100%"
          justifyContent="space-between"
          gap={isMobile ? '16px' : '40px'}
        >
          <Blurb
            numberText="01"
            numberBgColor="gray.500"
            numberColor="#1a1a1a"
            numberBorderColor="gray.300"
            heading="A 360° view of your estate"
            body="Get instant clarity on attacks against your workforce, risk hotspots, and untapped security features. Reach understands who the attackers are, what the targets mean for you, and how each attack works, in detail."
          />
          <Blurb
            numberText="02"
            numberBgColor="electricBlue.600"
            numberColor="electricBlue.100"
            numberBorderColor="electricBlue.300"
            heading="Security tailored to you"
            body="Reach analyzes millions of data points to understand how you’re being attacked and determine the best possible response, tailored to the risks you’re facing and the tools you have available."
            delay={0.15}
          />
          <Blurb
            numberText="03"
            numberBgColor="reachTeal.400"
            numberColor="reachTeal.800"
            numberBorderColor="reachTeal.500"
            heading="Autopilot activated"
            body="Tune configurations, remediate gaps, and ensure security controls are optimized, automatically or with one click. Reach delivers configurations as code, end-to-end automation, and detailed deployment guides that can be parsed into change control tickets."
            delay={0.3}
          />
        </Flex>
        <Center>
          <FadeIn delay={0.6}>
            <Button
              as={NavLink}
              to="/product"
              h="64px"
              rightIcon={<Icon as={AiOutlineArrowRight} />}
              size="lg"
              colorSheme="reachBlue"
              fontSize="24px"
              variant="outline"
            >
              Learn More
            </Button>
          </FadeIn>
        </Center>
      </ContentMaxWidth>
    </DarkMode>
  )
}

export default WhatWeDo
