/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Heading, LightMode, Link } from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import zach from './../TRABlog/ZachMarks.png'
import csp_okta from './csp_okta.png'
import csp_quest_summary from './csp_quest_summary.png'
import csp_quest from './csp_quest.png'
import BlogAuthor from '../../BlogAuthor'
import { BlogImage } from '../TRABlog'

BlogImage.propTypes = {
  src: PropTypes.any,
}

const OktaNetworkZonesBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Cloudy with a Chance of Account Takeover
              </Heading>
              <Flex py="8px" alignItems="center" justifyContent="space-around">
                <Box fontSize="12px">August 15, 2024</Box>
                <BlogAuthor name="Zach Marks" img={zach} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <Box>
                Credential compromise is a beachhead for high-profile attacks,
                making user authentication more critical than ever. IAM tools
                like Okta offer powerful ways to combat this risk, but fully
                optimizing your licensed capabilities can be a lengthy and
                challenging process.
              </Box>
              <Box mb="16px">
                Beyond risk, contextual, and behavioral based sign-on attributes
                included in the adaptive feature set, an often-overlooked
                component within Okta are network zones. Network zones provide a
                dynamic way to track IPs affiliated with a particular
                geolocation – both physical (e.g. country) and in the cloud
                (e.g. IPs affiliated with cloud providers).
              </Box>
              <Box mb="16px">
                Let’s dive into how the latter is a powerful way to thwart
                advanced techniques used by attackers once credentials are
                compromised.
              </Box>

              <Box mb="16px">
                <Heading size="md" mb="16px">
                  The Challenge of CSPs in Cybersecurity
                </Heading>
                The shift to the Cloud brings many ways to access free trials of
                Cloud Service Providers (CSPs). Just about any provider will
                offer a trial license to entice businesses to build on their
                platform. While access to these free trials enable innovation,
                it also provides a means for attackers to spin up servers and
                anonymize the geolocation of their malicious activity.
              </Box>
              <Box fontStyle="italic" mb="16px">
                Read more:{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://www.mdpi.com/2073-431X/8/4/81"
                >
                  IP Spoofing In and Out of the Public Cloud
                </Link>
              </Box>

              <Box mb="16px">
                When it comes to sources of authentication attempts, tracking
                known-bad (or out of policy) providers becomes imperative when
                applying guardrails on access to your environment, as these
                providers are often the first place attackers will go to spoof
                their location.
              </Box>
              <Box mb="16px">
                But, IPs affiliated with these cloud providers are ephemeral in
                nature and constantly changing. So, how do you track a target
                that’s moving?
              </Box>
              <Heading size="md" mb="16px">
                Dynamic Network Zones in Okta – Tracking and Blocking
              </Heading>
              <Box mb="16px">
                Statically assigned IP based block policies affiliated with CSPs
                can become outdated fast. If you were to deploy a series of IPs
                affiliated with a known out of policy provider today, they’ll
                likely change tomorrow. This, after all, is the nature of how
                CSPs work. When it comes to tracking, manual maintenance of
                these lists impossible to do at scale. That’s where Okta and
                Reach come into play.
              </Box>
              <Box mb="64px">
                Reach addresses this pivotal vector by analyzing sign-on logs to
                spot nefarious login attempts from unfamiliar or unsanctioned
                CSPs. Let’s say 99.8% of your organizations sign-in attempts in
                the last year originated from AWS and Azure, and .2% originated
                from Alibaba Cloud or Tencent (and failed with high risk level
                assigned to the login attempts by Okta). Reach identifies this
                nuance in sign-in data and creates a recommendation to block
                login attempts originating from both Alibaba Cloud or Tencent
                moving forward, utilizing Network Zones in Okta as the means
                house these IPs.
              </Box>
              <BlogImage src={csp_quest_summary} />
              <Box fontStyle="italic" mb="64px">
                Reach surfaces risky Cloud Service Providers based on sign-in
                logs and makes recommendations based on your risk profile.
              </Box>
              <BlogImage src={csp_quest} />
              <Box fontStyle="italic" mb="64px">
                Automatically stage IPs affiliated with Non-Sanctioned Cloud
                Providers to your Okta instance as Network Zones to block on.
              </Box>
              <Heading size="md" mb="16px">
                Integrated Intelligence
              </Heading>
              <Box mb="64px">
                Reach’s dedicated threat research team maintains intelligence
                that tracks IPs affiliated with these known-bad providers and
                constantly updates these curated Network Zone(s) in Okta to
                allow you to stay on top of the known-bad CSP’s IP space. This
                intelligence, built and maintained by systems in-house by Reach,
                is curated for your unique deployment of Okta and integrates
                seamlessly, with updates to addresses pushed dynamically as IPs
                affiliated with these CSPs evolve overtime. Reach will also
                construct a block policy that references these dynamic Network
                Zones, allowing you to not only monitor the moving target, but
                action on it.
              </Box>
              <BlogImage src={csp_okta} />
              <Box fontStyle="italic" mb="64px">
                Reach maintains intelligence affiliated with these providers and
                dynamically updates the Block Zones within Okta as IPs change
                over time.
              </Box>
              <Heading size="md" mb="16px">
                Conclusion: Strengthening IAM Security with Reach and Okta
              </Heading>
              <Box mb="16px">
                Integrating Reach with Okta is a game-changer for boosting your
                security in the event of credential compromise. With Reach’s
                advanced analytics and proactive recommendations, organizations
                can better defend against threats from attackers looking to
                spoof their location and gain access to sensitive
                infrastructure. This combo not only maximizes the value of
                Okta’s Adaptive features, but also adds an extra layer of
                security for a more comprehensive approach to your IAM
                deployment.
              </Box>
              <Heading size="md" mb="16px">
                More About Reach Security
              </Heading>
              <Box>
                Reach is the first AI purpose-built to reprogram your security
                infrastructure based on who you are and how you're being
                attacked. Organizations of all sizes trust Reach to make
                mission-critical decisions because it doesn't hallucinate and it
                doesn't make mistakes. It's a different kind of AI. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default OktaNetworkZonesBlog
