import React from 'react'
import { Box, Flex, Heading } from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { useApp } from 'context/App'
import { Widget } from '@typeform/embed-react'
import FadeIn from 'components/Shared/FadeIn'

const TYPEFORM_UUID = 'Gh8mrh9u'
// const TYPEFORM_UUID = 'QlkWt6nq'

const SplashArea = () => {
  const { isMobile } = useApp()
  return (
    <>
      <Box
        minH={isMobile ? 'auto' : '88vh'}
        h={isMobile ? 'auto' : '800px'}
        pt={isMobile ? '120px' : '24px'}
        color="white"
        bg="black"
        overflow="visible"
      >
        <ContentMaxWidth maxW="1400px" w="100%" h={isMobile ? 'auto' : '100%'}>
          <Flex
            flexDir={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            w="100%"
            h={isMobile ? 'auto' : '100%'}
            alignItems={isMobile ? 'flex-start' : 'center'}
            mb={isMobile ? '32px' : '56px'}
          >
            <Box
              maxW="420px"
              mr={!isMobile && '64px'}
              px={isMobile ? '16px' : undefined}
              flex={1}
            >
              <FadeIn mb="32px">
                <Heading
                  fontSize={isMobile ? 40 : 48}
                  color="white"
                  display="inline"
                  lineHeight={1}
                  pt="92px"
                  ml="2px"
                  mb="32px"
                >
                  Tools Rationalization Assessment
                </Heading>
              </FadeIn>
              <FadeIn delay={0.4}>
                <Box
                  fontSize="20px"
                  fontWeight="light"
                  lineHeight={1.4}
                  mb="8px"
                >
                  Reach’s tools rationalization assessment empowers security
                  leaders to measure (and celebrate) how well their products are
                  configured to protect their organization with results in
                  minutes.
                </Box>
              </FadeIn>
            </Box>
            <Box
              flex={1}
              h="100%"
              w={isMobile && '100%'}
              mb={isMobile && '80px'}
              position="relative"
              pt={!isMobile && '108px'}
              pl={!isMobile && '24px'}
              maxH="800px"
              zIndex={1}
            >
              <Widget
                id={TYPEFORM_UUID}
                style={{
                  zIndex: 100,
                  width: '100%',
                  height: isMobile ? '80vh' : '100%',
                }}
              />
            </Box>
          </Flex>
        </ContentMaxWidth>
      </Box>
    </>
  )
}

export default SplashArea
