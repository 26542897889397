import { useApp } from 'context/App'
import React from 'react'
import FadeIn from './FadeIn'
import { Box, Flex, Image } from '@chakra-ui/react'
import PropTypes from 'prop-types'

const Testimonial = ({ quote, author, title, logoPath, delay = 0 }) => {
  const { isMobile } = useApp()
  return (
    <FadeIn
      maxW={isMobile ? undefined : '360px'}
      mb={isMobile ? '8px' : 0}
      flexShrink={0}
      delay={delay}
    >
      <Flex
        flexDir="column"
        alignItems="center"
        h="400px"
        p="32px"
        bg="#171717"
        position="relative"
      >
        <Flex
          flexDirection="column"
          h={isMobile ? 'auto' : '100%'}
          pt={isMobile ? '40px' : undefined}
        >
          <Box flex={1}>
            <Box
              flex={1}
              textAlign="left"
              color="#FFF"
              fontStyle="italic"
              mb="16px"
              fontSize="18px"
            >
              &quot;{quote}&quot;
            </Box>
            <Box flex={1} textAlign="left" fontWeight="bold" color="#FFF">
              {author}
            </Box>
            <Box
              flex={1}
              textAlign="left"
              fontWeight="bold"
              color="#FFF"
              mb="16px"
            >
              {title}
            </Box>
          </Box>
          <Box>
            <Flex
              bg="#fff"
              p="16px"
              alignItems="center"
              justifyContent="center"
              h="50px"
            >
              <Image w={isMobile ? '50%' : '80%'} src={logoPath} />
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </FadeIn>
  )
}

Testimonial.propTypes = {
  quote: PropTypes.string,
  author: PropTypes.string,
  title: PropTypes.string,
  logoPath: PropTypes.string,
  delay: PropTypes.any,
}

export default Testimonial
