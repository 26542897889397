import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  DarkMode,
  HStack,
  Heading,
  LightMode,
} from '@chakra-ui/react'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import VideosBody from './Videos/VideosBody'
import NewsBody from './News/NewsBody'
import BlogBody from './Blog/BlogBody'
import BlackHatMeetReach from './BlackHatMeetReach/BlackHatMeetReach'
import ContentLibrary from './ContentLibrary'
import ReachRaise from './News/Articles/ReachRaise'
import CrowdstrikePartnershipArticle from './News/Articles/CrowdstrikePartnership'

const RESOURCES_VIEWS = {
  NEWS: '/resources/news',
  VIDEOS: '/resources/videos',
  BLOG: '/resources/blog',
  CONTENTLIBRARY: '/resources/content-library',
}

const ResourceButton = ({ route, currentPath, ...props }) => {
  const history = useHistory()
  const isSelected = currentPath === route

  return (
    <Button
      colorScheme="gray"
      variant={isSelected ? 'outline' : 'ghost'}
      border="2px solid"
      fontWeight={isSelected ? 'bold' : 'normal'}
      borderColor={isSelected ? 'gray.600' : 'transparent'}
      onClick={() => history.push(route)}
      {...props}
    />
  )
}

ResourceButton.propTypes = {
  currentPath: PropTypes.string,
  route: PropTypes.string,
}

const ResourcesBody = () => {
  const location = useLocation()

  return (
    <LightMode>
      <Box minH="100vh" color="black">
        <Box bg="black" pt="120px" pb="32px" color="white">
          <ContentMaxWidth
            flexDir="column"
            maxW="1600px"
            h="100%"
            alignItems="start"
          >
            <Heading px="8px" mb="16px">
              Resources
            </Heading>

            <DarkMode>
              <HStack p="8px" flexWrap="wrap">
                <ResourceButton
                  route={RESOURCES_VIEWS.NEWS}
                  currentPath={location.pathname}
                >
                  News
                </ResourceButton>
                <ResourceButton
                  route={RESOURCES_VIEWS.VIDEOS}
                  currentPath={location.pathname}
                >
                  Videos
                </ResourceButton>
                <ResourceButton
                  route={RESOURCES_VIEWS.BLOG}
                  currentPath={location.pathname}
                >
                  Blog
                </ResourceButton>
                <ResourceButton
                  route={RESOURCES_VIEWS.CONTENTLIBRARY}
                  currentPath={location.pathname}
                >
                  Content Library
                </ResourceButton>
              </HStack>
            </DarkMode>
          </ContentMaxWidth>
        </Box>
        <Box>
          <Switch>
            {/* news articles go above the /resources/news route */}
            <Route exact path="/resources/news/crowdstrike_marketplace">
              <CrowdstrikePartnershipArticle />
            </Route>
            <Route exact path="/resources/news/reach_raise">
              <ReachRaise />
            </Route>
            {/* end news articles */}
            <Route path="/resources/news">
              <NewsBody />
            </Route>
            <Route path="/resources/videos">
              <VideosBody />
            </Route>
            <Route path="/resources/blog">
              <BlogBody />
            </Route>
            <Route path="/resources/meet-reach-black-hat">
              <BlackHatMeetReach />
            </Route>
            <Route path="/resources/content-library">
              <ContentLibrary />
            </Route>
          </Switch>
        </Box>
      </Box>
    </LightMode>
  )
}

export default ResourcesBody
