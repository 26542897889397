/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  LightMode,
  Link,
  ListItem,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react'
import PageHeader from 'components/Shared/PageHeader'
import PageFooter from 'components/Shared/PageFooter'
import { useApp } from 'context/App'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'

import mapChange from './map_change.png'
import traOkta from './tra_okta.png'
import personaDrilldown from './persona_drilldown.png'
import s1_control1 from './s1_control1.png'
import s1_control2 from './s1_control2.png'
import panos_control1 from './panos_control1.png'
import okta_control1 from './okta_control1.png'
import okta_control2 from './okta_control2.png'
import okta_control3 from './okta_control3.png'
import zach from './ZachMarks.png'

import FadeIn from 'components/Shared/FadeIn'
import B from 'components/Shared/B'
import BlogAuthor from '../../BlogAuthor'

export const BlogImage = ({ src, ...remaining }) => (
  <FadeIn>
    <Image
      borderRadius="8px"
      overflow="hidden"
      border="1px solid"
      borderColor="gray.200"
      my="16px"
      src={src}
      {...remaining}
    />
  </FadeIn>
)

BlogImage.propTypes = {
  src: PropTypes.any,
}

const ToolsRationalizationBlog = () => {
  const { setHeaderBgColor, isMobile } = useApp()

  useEffect(() => {
    window.scrollTo(0, 0)
    setHeaderBgColor('#000')
    return () => setHeaderBgColor(null)
  }, [])

  return (
    <>
      <PageHeader />
      <LightMode>
        <Box minH="100vh" pt={isMobile ? '120px' : '184px'} pb="80px">
          <ContentMaxWidth maxW="880px" color="black">
            <Box
              fontSize={isMobile ? '18px' : '20px'}
              px={isMobile ? '16px' : undefined}
            >
              <Heading
                lineHeight={1.5}
                mb="16px"
                fontSize={isMobile ? '30px' : '38px'}
                textAlign="center"
              >
                Why an identity-centric approach to Tools Rationalization is the
                place to start
              </Heading>
              <Flex py="8px" alignItems="center" justifyContent="space-around">
                <Box fontSize="12px">May 21, 2024</Box>
                <BlogAuthor name="Zach Marks" img={zach} />
                <Box fontSize="12px">5 minute read</Box>
              </Flex>
              <BlogImage src={mapChange} />
              <BlogImage src={traOkta} />
              <Box>
                It’s no surprise that attacks targeting end users aren’t slowing
                down. According to the 2024 Verizon DBIR, a staggering 68% of
                all breaches involve the notorious human element, which was
                roughly the same as 2023. On the contrary, less than 1% of cyber
                exposures stem from CVEs according to new research by Cyentia
                institute. Since humans continue to be the weakest link, it’s
                time we prioritize preventative controls to best address the way
                in which they (the users) are being attacked.
              </Box>
              <Box mb="16px">
                At Reach, we’ve identified that almost all identity-centric
                attacks delivered to an organization’s workforce are preventable
                using the tooling in place. Examples of attacks that target the
                end user include:
                <UnorderedList pt="16px" lineHeight={1.7}>
                  <ListItem>
                    URL-based Phishing (corporate and consumer)
                  </ListItem>
                  <ListItem>Ransomware</ListItem>
                  <ListItem>Malware (executable attachments)</ListItem>
                  <ListItem>Business email compromise</ListItem>
                  <ListItem>Keyloggers</ListItem>
                </UnorderedList>
              </Box>

              <Box mb="16px">
                Gartner predicts that by 2025, half of all cyber incidents will
                occur because of a “lack of talent” or human error.
                Organizations assume they are covered when it comes to
                protecting their knowledge workers, but oftentimes there are
                cracks.
              </Box>

              <Box mb="16px">
                Limiting the blast radius is feasible. It’s a big data problem,
                often involving big data readily available to security
                operators.
              </Box>

              <Box mb="16px">
                To address identity-centric attacks it’s important to start with
                both:
                <OrderedList pt="16px" lineHeight={1.8}>
                  <ListItem>
                    An inventory of the minority of the workforce that
                    represents a majority of the overall risk
                  </ListItem>
                  <ListItem>
                    The ways in which your products that have some concept of an
                    identity - typically Email Security, Network Security,
                    Endpoint Detection and Response (EDR), and Identity and
                    Access Management (IAM) products – can interoperate as a
                    cohesive unit
                  </ListItem>
                </OrderedList>
              </Box>

              <Box mb="16px">
                This two-pronged approach enables organizations to achieve
                defense in depth while pinpointing where risk hotspots live.
              </Box>

              <Box mb="16px">
                Before we dive into it, we want to make an important
                distinction. We’re not here to tell you we can identify the “8%
                of the population that accounts for 80% of the risk”, even
                though we do. We’re here to tell you that we’ll tailor your
                security estate to best protect these users and ensure the best
                defenses possible are in place for them at any given moment in
                time.
              </Box>

              <Center>
                <Image
                  w="100%"
                  maxW="512px"
                  src="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/quest-images/PANOS_DNS_FILTERING.png"
                />
              </Center>
              <Box fontStyle="italic" fontSize="16px">
                The human element was a component of 68% of breaches, roughly
                the same as the previous period described in the 2023 Verizon
                DBIR.
              </Box>

              <br />

              <Box mb="32px">
                <Heading size="md">
                  Part 1: Establishing a backbone to inform “where” and “how”
                </Heading>
                <br />
                To start, some products claim to “fight AI employee risk” by
                identifying risk hotspots and giving you a list. We’re again
                here to tell you (scream to you) that simply identifying
                employee risk hotspots is not enough! It is, however, an
                important first step. There are important considerations to
                weigh when it comes to normalizing these data points, as the
                accuracy becomes incredibly important once we get to tailoring
                security. For example:
                <UnorderedList pt="16px" lineHeight={1.7}>
                  <ListItem>
                    Are mailing lists collapsed to individual identities? (Hint:
                    you can’t deploy an EDR rule to a shared mailbox)
                  </ListItem>
                  <ListItem>
                    Are ex-employees from stale AD records still showing up?
                  </ListItem>
                  <ListItem>
                    Are proxy addresses, whose identity your email security
                    provider doesn’t understand, taking up more weight than real
                    users?
                  </ListItem>
                </UnorderedList>
                Understanding where risk lives within the workforce continuously
                is an imperative first step.
              </Box>

              <BlogImage src={personaDrilldown} />

              <Box>
                <Heading size="md">
                  Part 2: Determining the optimal set of configuration
                  combinations based on your risk profile
                </Heading>
                <br />
                Security product forensics reveal how to leverage your licensed
                capabilities. These data points can then be overlayed with what
                you have deployed, what it’s capable of, and how you’re using
                it.
                <br />
                For instance, if a phishing campaign uses URLs that bypass DNS
                security checks and redirect users to a web page hosted on a
                public IP address, forensics from your email security product
                can identify:
                <UnorderedList pt="16px" lineHeight={1.7}>
                  <ListItem>
                    Where within the workforce these URLs were delivered
                  </ListItem>
                  <ListItem>Which users clicked the URLs</ListItem>
                  <ListItem>The attributes of the phishing page</ListItem>
                </UnorderedList>
                This data helps create a "most phished" group, focusing on
                high-risk areas.
              </Box>
              <br />
              <Box mb="32px">
                <Heading size="md">
                  Part 3: Mapping and deploying available controls from the
                  holistic security estate to combat identity-centric attack
                  chains
                </Heading>
                <br />
                Layer in compensating controls from your Network, EDR, and IAM
                products to address identity-centric attack chains.
                <br />
                To start, we’ll utilize your Endpoint product’s browser
                capabilities to create a custom rule that both:
                <OrderedList pt="16px" lineHeight={1.8}>
                  <ListItem>
                    Alerts you when a user browses to a public IP address (and
                    bypasses DNS checks)
                  </ListItem>
                  <ListItem>
                    Alerts you when a user submits credentials on a suspected
                    phishing page hosted on a public IP address
                  </ListItem>
                </OrderedList>
                <BlogImage mb="8px" src={s1_control1} />
                <Box fontStyle="italic" fontSize="16px" mb="16px">
                  Alert when a user communicates with a Public IP address
                  phishing page, but only if the user is disproportionally
                  phished.
                </Box>
                <BlogImage mb="8px" src={s1_control2} />
                <Box fontStyle="italic" fontSize="16px">
                  Alert when a user submits credentials on a web form hosted on
                  a public IP address that's suspected to be phishing, but only
                  if the user is disproportionality phished.
                </Box>
                <br />
                We'll then conduct a similar exercise on the firewall, creating
                and implementing custom App IDs to apply content filtering
                (blocking, in this case) when users browse to a public IP
                address.
                <br />
                <br />
                <BlogImage mb="8px" src={panos_control1} />
                <Box fontStyle="italic" fontSize="16px">
                  Create internet outbound App-ID to block layer 7 traffic
                  routing to public IP addresses, agnostic of URL category.
                </Box>
                <br />
                To ensure a layered defense in the event of credential
                compromise, we'll then add compensating controls using your IAM
                product to limit the blast radius if the attack is carried out
                successfully. We'll want to ensure all contextual and risk-based
                authentication attributes are layered into sign-on policies as
                the likelihood of anomalous login (e.g. logins originating from
                non-sanctioned cloud providers, new regions, risk levels
                assigned by vendor) are high. Examples below utilize
                capabilities you may be paying for in Okta's Adaptive MFA and
                SSO modules.
                <br />
                <BlogImage mb="8px" src={okta_control1} />
                <Box fontStyle="italic" fontSize="16px">
                  For most phished users, shorten session time to 1 hour if risk
                  level of login is medium and sign-in originates from
                  non-sanctioned cloud provider.
                </Box>
                <BlogImage mb="8px" src={okta_control2} />
                <Box fontStyle="italic" fontSize="16px">
                  For most phished users, shorten session time to 1 hour if
                  login originates from a new region.
                </Box>
                <BlogImage mb="8px" src={okta_control3} />
                <Box fontStyle="italic" fontSize="16px">
                  For most phished users, shorten session time to 1 minute if
                  risk level of sign-in is deemed to be high.
                </Box>
                <br />
                From start to finish, this is clearly a <B>staggering</B> amount
                of work to do manually, which is why Reach exists.
              </Box>

              <br />

              <Box mb="32px">
                <Heading size="md">
                  Layering defenses from an identity-centric point of view
                </Heading>
                <br />
                You may hear terms like "tools rationalization" or "maximizing
                tool effectiveness," but their meanings can vary. Are you
                following a vendor best practice checklist, mapping CVEs to
                specific configurations, or tracking workforce risk and ensuring
                your licensed capabilities address this risk? Whichever path you
                choose, understanding the value of each approach is crucial.
              </Box>

              <Heading size="md">More About Reach Security</Heading>
              <br />
              <Box>
                Reach is the first AI purpose-built to reprogram your security
                infrastructure based on who you are and how you're being
                attacked. Organizations of all sizes trust Reach to make
                mission-critical decisions because it doesn't hallucinate and it
                doesn't make mistakes. It's a different kind of AI. To learn
                more, visit{' '}
                <Link
                  fontWeight="bold"
                  target="_blank"
                  href="https://reach.security/try-reach"
                >
                  reach.security/try-reach
                </Link>
                .
              </Box>
            </Box>
          </ContentMaxWidth>
        </Box>
      </LightMode>
      <PageFooter />
    </>
  )
}

export default ToolsRationalizationBlog
