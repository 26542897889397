import React from 'react'
import { Box, Stack, LightMode } from '@chakra-ui/react'
import BlogEntry from './BlogEntry'
import ContentMaxWidth from 'components/Shared/ContentMaxWidth'

const BlogBody = () => {
  return (
    <LightMode>
      <ContentMaxWidth maxW="1600px" h="100%" alignItems="start">
        <Box py="64px">
          <Stack
            gridTemplateColumns="repeat(auto-fit, minmax(320px, 1fr));"
            gap="16px"
          >
            <BlogEntry
              delay={0}
              bgSize="50%"
              title="Cloudy with a Chance of Account Takeover"
              description="Integrating Reach with Okta is a game-changer for boosting your security in the event of credential compromise. 
              With Reach’s advanced analytics and proactive recommendations, organizations can better defend against threats from attackers 
              looking to spoof their location and gain access to sensitive infrastructure. This combo not only maximizes the value of Okta’s Adaptive features,
              but also adds an extra layer of security for a more comprehensive approach to your IAM deployment."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/blog_images/Okta_Wordmark_CMYK_Black.png"
              internal
              url="/resources/blog/okta_integration"
            />
            <BlogEntry
              delay={0}
              bgSize="50%"
              title="The Power of Automation with Reach"
              description="At Reach, we prioritize the safety and control of our users by
                leveraging a staged environment for automation. This approach
                not only safeguards the production environment but also ensures
                that changes are meticulously tested and approved before going
                live. Whether it’s implementing Conditional Access policies or
                deploying other critical updates, Reach’s staged automation
                empowers organizations to increase their cybersecurity posture
                with confidence, efficiency, and ease."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/blog_images/automation_blog_cover.png"
              internal
              url="/resources/blog/automation"
            />
            <BlogEntry
              delay={0}
              bgSize="80%"
              title="Bridging the Last Mile: Reach’s New ServiceNow Integration Enhances Cybersecurity Maturity  "
              description="As we continue to navigate the complexities of cybersecurity, our focus remains on providing solutions that not only secure but also streamline operational processes. The new ServiceNow integration is a testament to our dedication to mastering the last mile, ensuring that our clients can achieve and maintain the highest levels of security maturity."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/blog_images/servicenow-footer-logo.svg"
              internal
              url="/resources/blog/servicenow_integration"
            />
            <BlogEntry
              delay={0}
              bgSize="80%"
              title="The Last Mile in Cybersecurity: Turning Assessments into Action"
              description="The last mile is a critical component of cybersecurity operations. By embracing a comprehensive approach that goes beyond mere reports to actionable and automated implementation, Reach ensures that your organization is not only secure but also agile and efficient. Stay tuned for our upcoming posts where we will delve deeper into specific use cases and solutions that exemplify our commitment to mastering the last mile in cybersecurity."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/blog_images/ASCA_maturity.png"
              internal
              url="/resources/blog/last_mile"
            />
            <BlogEntry
              delay={0}
              bgSize="80%"
              title="Security Tools Optimization Spotlight: Microsoft Entra ID
              Conditional Access"
              description="Conditional Access contains simple yet powerful sign-on
              attributes that can be tailored to your business with a
              risk-based approach. Whether you're undergoing security tool
              consolidation efforts or embarking on an IAM hardening project,
              consider starting with a series of tailored sign-on policies
              that address risk where most breaches start."
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/quest-images/thumbnails/CONDITIONAL_ACCESS_SIGN_ON_BLOCK_HIGH_RISK_EXCLUDE_MANAGED_OR_REGISTERED.png"
              internal
              url="/resources/blog/microsoft_entra_spotlight"
            />

            <BlogEntry
              delay={0}
              bgSize="80%"
              title="Why an identity-centric approach to Tools Rationalization is the place to start"
              description='You may hear terms like "tools rationalization" or "maximizing tool effectiveness," but their meanings can vary. Are you following a vendor best practice checklist, mapping CVEs to specific configurations, or tracking workforce risk and ensuring your licensed capabilities address this risk? Whichever path you choose, understanding the value of each approach is crucial.'
              imageUrl="https://reach-public-static-assets.s3.us-west-1.amazonaws.com/quest-images/PANOS_DNS_FILTERING.png"
              internal
              url="/resources/blog/identity_centric_tools"
            />
            <Box />
          </Stack>
        </Box>
      </ContentMaxWidth>
    </LightMode>
  )
}

export default BlogBody
